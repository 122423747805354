import "./programs.scss"
import * as React from "react"
import { Row, Col, Button, Container, Badge } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image";

const ProgramsEducationGB = ({ location }) => {

    const mapOfOpenPositions = [
        {
            title: "Front Developer",
            location: "San francisco, CA - United States",
            url: "/careers/job-description/",
            urgent: true,
            tags: ["Remote", "Office"],
            introduction: "It was a difficult project given the scope of what we were trying to do and they helped us bring it to life. It was a difficult project given the scope of what we were trying to do and they helped us bring it to life ..."
        },
        {
            title: "Front Developer",
            location: "San francisco, CA - United States",
            url: "/careers/job-description/",
            urgent: false,
            tags: [],
            introduction: "It was a difficult project given the scope of what we were trying to do and they helped us bring it to life. It was a difficult project given the scope of what we were trying to do and they helped us bring it to life ..."
        },
        {
            title: "Front Developer",
            location: "San francisco, CA - United States",
            url: "/careers/job-description/",
            urgent: false,
            tags: [],
            introduction: "It was a difficult project given the scope of what we were trying to do and they helped us bring it to life. It was a difficult project given the scope of what we were trying to do and they helped us bring it to life ..."
        },
        {
            title: "Front Developer",
            location: "San francisco, CA - United States",
            url: "/careers/job-description/",
            urgent: false,
            tags: [],
            introduction: "It was a difficult project given the scope of what we were trying to do and they helped us bring it to life. It was a difficult project given the scope of what we were trying to do and they helped us bring it to life ..."
        }
    ]

    let listOfOpenPositions = mapOfOpenPositions.map(({ title, location, url, urgent, tags, introduction }, index) => (
        <li className="row" key={index}>
            <Col md={4} lg={3}>
                <h6>{title} {urgent ? <Badge bg="success">HOT</Badge> : ""}</h6>
                <span className="location">
                    {location}
                    {tags.length > 0 ? tags.map((tag, index) => <Badge key={index} bg="danger">{tag}</Badge>) : ""}
                </span>
            </Col>
            <Col md={6} lg={9}>
                <Row>
                    <Col className="introduction" lg={8}>
                        <p>{introduction}</p>
                    </Col>
                    <Col className="actions" lg={4}>
                        <Button variant="success" href={url}>View and Apply</Button>
                        <span className="share"><a href="/">Share</a></span>
                    </Col>
                </Row>
            </Col>
            <hr></hr>
        </li>
    ));

    return (
        <>


            <section className="header-educationgb">

                <Row className="back-header-gb">

                    <Col className="back-header-text-gb  " xs={12} sm={12} md={7} lg={7}>

                        <span className="education-link">&#60; Education</span><br></br><br></br><br></br>

                        <span className="name-program">Frontend Developer</span>
                        <p>
                            <Button className="btn-apply">Apply</Button><br></br><br></br><br></br>
                            <span className=" "> Copy Link</span>
                            <StaticImage
                                className="copy-link-icon"
                                alt="Copy link icon"
                                src="../../images/education/copy-link-icon.svg"
                                formats={["auto", "webp"]}
                            />
                            <br></br><br></br><br></br>
                        </p>
                    </Col>



                </Row>





            </section >



            <section className="program-description">
                <Row>
                    <Col className="col-text" lg={12} >
                        <div className="wrapper-text">
                            <h3 className="header-square green md-large">
                                Program Description
                            </h3>
                            <p>
                                Nurturing our culture and values should remain the one fundamental focus of all our employees,
                                and even more so of our company leaders, but as our company continues to grow rapidly, the risk of departing
                                from this core focus also increases, as we will inevitably continue to create more rules, more processes,
                                and more structure.



                            </p>

                            <div className="btn-pack-xl">

                                <Button className="learn-more">Learn more</Button>
                                <Button className="btn-apply">Apply</Button>
                            </div>



                        </div>
                    </Col>

                </Row>
            </section>

            <section className="join-team">

                <div className="max-container">
                    <Row className="" >

                        <Col lg={4}>
                            <div className="requirements-img"></div>
                            <h4>Requirements</h4>

                        </Col>
                        <Col lg={4}>
                            <div className="ouralumnus-img">

                            </div>
                            <h4>Our alumnus</h4>

                        </Col>
                        <Col lg={4}>
                            <div className="jointeam-img"></div>
                            <h4>Join our team</h4>

                        </Col>

                    </Row>
                </div>
            </section>


            <section className="section-related-jobs-openings">
                <Container className="section-wrap" fluid="xl">
                    <Row>
                        <Col md={4} lg={12}>
                            <h5 className="header-square green">
                                Available Programs
                            </h5>
                        </Col>
                        <Col md={6}>

                        </Col>
                    </Row>
                    <Row>
                        <ul>
                            {listOfOpenPositions}
                        </ul>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ProgramsEducationGB
